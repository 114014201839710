// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sc d_gv d_cr";
export var heroHeaderCenter = "v_gw d_gw d_cr d_dv";
export var heroHeaderRight = "v_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "v_sd d_gr d_cv";
export var heroParagraphCenter = "v_gs d_gs d_cv d_dv";
export var heroParagraphRight = "v_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "v_sf d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "v_sg d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "v_sh d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "v_sj d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "v_sk d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "v_sl z_sl";
export var heroExceptionNormal = "v_sm z_sm";
export var heroExceptionLarge = "v_sn z_sn";
export var Title1Small = "v_sp z_sp z_sH z_sJ";
export var Title1Normal = "v_sq z_sq z_sH z_sK";
export var Title1Large = "v_sr z_sr z_sH z_sL";
export var BodySmall = "v_ss z_ss z_sH z_s0";
export var BodyNormal = "v_st z_st z_sH z_s1";
export var BodyLarge = "v_sv z_sv z_sH z_s2";