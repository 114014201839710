// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rG d_fn d_bF d_dt";
export var alignLeft = "t_qH d_fn d_bF d_dt";
export var alignDiscCenter = "t_rH d_fp d_bC d_dv";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignDiscRight = "t_rJ d_fq d_bG d_dw";
export var alignRight = "t_qJ d_fq d_bG d_dw";
export var footerContainer = "t_rK d_dV d_bV";
export var footerContainerFull = "t_rL d_dS d_bV";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_rM d_v";
export var footerDisclaimerWrapper = "t_km d_km d_cj";
export var badgeWrapper = "t_rN d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "t_rP undefined";
export var footerDisclaimerLeft = "t_rQ undefined";
export var verticalTop = "t_rR d_by d_bH d_bM d_bK";
export var firstWide = "t_rS";
export var disclaimer = "t_rT d_by d_bH";
export var socialDisclaimer = "t_rV";
export var left = "t_rW";
export var wide = "t_rX d_cx";
export var right = "t_rY d_bJ";
export var line = "t_fj d_fk d_ct";
export var badgeDisclaimer = "t_rZ d_bB d_bN d_bH";
export var badgeContainer = "t_r0 d_by d_bG d_bN";
export var badge = "t_r1";
export var padding = "t_r2 d_c6";
export var end = "t_r3 d_bG";
export var linkWrapper = "t_r4 d_dz";
export var link = "t_n2 d_dz";
export var colWrapper = "t_r5 d_cw";
export var consent = "t_f d_f d_bB d_bN";
export var media = "t_r6 d_bw d_dx";
export var itemRight = "t_r7";
export var itemLeft = "t_r8";
export var itemCenter = "t_r9";
export var exceptionWeight = "t_sb z_s7";