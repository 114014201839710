// extracted by mini-css-extract-plugin
export var articleWrapper = "f_mr";
export var articleText = "f_ms d_dt d_cr";
export var header = "f_mt d_Y d_v";
export var headerImageWrapper = "f_mv d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "f_mw d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "f_mx d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "f_my d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "f_mz d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "f_mB d_v";
export var dateTag = "f_mC d_cd d_cq d_dp d_v";
export var icon1 = "f_mD d_cN";
export var icon2 = "f_mF d_cN d_cH";
export var tagBtn = "f_mG d_b0 d_cC d_dn d_dz";
export var headerText = "f_mH d_v d_cd d_cr d_bV";
export var center = "f_mJ d_dv";
export var videoIframeStyle = "f_mK d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "f_mL d_cr d_Y";
export var articleImageWrapperIcon = "f_mM d_cr d_Y";
export var articleRow = "f_mN d_bC";
export var quoteWrapper = "f_gQ d_bB d_bN d_cr";
export var quoteBar = "f_mP d_G";
export var quoteText = "f_mQ";
export var authorBox = "f_mR d_v";
export var authorRow = "f_mS d_bC d_bV d_cx";
export var separator = "f_mT d_v";
export var line = "f_fj d_v d_fj d_cX d_cx";
export var authorImage = "f_lp d_b6 d_Y d_v d_cs";
export var authorText = "f_mV d_cg d_cs";
export var masonryImageWrapper = "f_mW";
export var bottomSeparator = "f_mX d_v d_cx";
export var linksWrapper = "f_mY d_cg d_dt";
export var comments = "f_mZ d_bC d_cx";
export var sharing = "f_m0 d_by d_bC d_bH";
export var shareText = "f_m1 d_v d_dv";
export var icon = "f_m2";
export var customRow = "f_m3 d_bC d_Y";
export var text = "f_m4";
export var SubtitleSmall = "f_m5";
export var SubtitleNormal = "f_m6";
export var SubtitleLarge = "f_m7";