// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "h_nS";
export var warningBackgrounddark = "h_nT";
export var warningBackgroundcustom = "h_nV";
export var bottom = "h_gH";
export var modal = "h_nW";
export var container = "h_nX";
export var btnWrapper = "h_d1 d_bC d_bN d_bH";
export var elementWrapper = "h_nY d_bC d_bN d_dv";
export var titleWrapper = "h_nZ d_bC d_bN";
export var cookieButtons = "h_n0 d_by d_bC d_bN d_bH";
export var btn = "h_n1 d_by d_bC d_bN";
export var link = "h_n2";
export var decline = "h_n3 h_n1 d_by d_bC d_bN";
export var row = "h_n4";