// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_tQ d_bC";
export var storyRowWrapper = "B_hx d_hx d_bJ";
export var storyLeftWrapper = "B_tR d_by d_bN";
export var storyWrapperFull = "B_tS d_cC";
export var storyWrapperFullLeft = "B_qr d_cC d_by d_bN d_bC";
export var contentWrapper = "B_mB d_hy";
export var storyLeftWrapperCenter = "B_tT d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "B_tV d_hF";
export var storyHeader = "B_tW d_hD d_v d_cr";
export var storyHeaderCenter = "B_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "B_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "B_tX d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "B_qM d_ff d_Y";
export var imageWrapperFull = "B_tY d_v d_G d_bd d_Y";