// extracted by mini-css-extract-plugin
export var noPadding = "C_fh d_fh";
export var videoFrameWrapper = "C_tZ d_kT d_b4";
export var videoIframeStyle = "C_mK d_d4 d_v d_G d_bx d_b0 d_Q";
export var videoLayoutRight = "C_t0 d_kS d_bJ";
export var videoInnerWrapper = "C_t1 d_fb d_d9 d_N d_Y d_by d_bC d_bN d_b4";
export var videoText = "C_t2 d_dv d_v";
export var videoInnerInnerWrapper = "C_t3 d_by d_bC d_bH";
export var btnWrapper = "C_d1 d_v d_by d_bC d_ch";
export var btn = "C_n1 d_b0 d_by d_bC d_bN d_cG d_b5 d_dz d_m z_tr z_s9";
export var icon = "C_m2 d_cB";