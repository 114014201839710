// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "H_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "H_fR d_fR d_by d_bH";
export var menuDesign6 = "H_vC d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "H_vD d_fM d_by d_dv d_bN d_bH";
export var menuRight = "H_vF d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "H_vG d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "H_vH d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "H_t9 d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "H_vl d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "H_vJ";
export var navbarItem = "H_vb d_bw";
export var navbarLogoItemWrapper = "H_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "H_vK d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "H_vL d_gd d_bx d_Y d_br";
export var burgerToggle = "H_vM d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "H_vN d_gd d_bx d_Y d_br";
export var burgerInput = "H_vP d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "H_vQ d_f3 d_v d_G";
export var burgerLine = "H_vR d_f1";
export var burgerMenuDesign6 = "H_vS d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "H_vT d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "H_vV d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "H_vW d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "H_vX d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "H_vY d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "H_vZ d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "H_v0 d_bB d_bN";
export var staticBurger = "H_v1";
export var menu = "H_v2";
export var navbarDividedLogo = "H_v3";
export var nav = "H_v4";