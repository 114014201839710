// extracted by mini-css-extract-plugin
export var lbContainerOuter = "k_ps";
export var lbContainerInner = "k_pt";
export var movingForwards = "k_pv";
export var movingForwardsOther = "k_pw";
export var movingBackwards = "k_px";
export var movingBackwardsOther = "k_py";
export var lbImage = "k_pz";
export var lbOtherImage = "k_pB";
export var prevButton = "k_pC";
export var nextButton = "k_pD";
export var closing = "k_pF";
export var appear = "k_pG";