// extracted by mini-css-extract-plugin
export var articleWrapper = "p_mr";
export var searchResult = "p_qS p_mr";
export var line = "p_fj d_fj d_cX";
export var articleTitle = "p_qT d_cp";
export var articleText = "p_ms d_cr";
export var authorImage = "p_lp d_lp d_b6 d_Y d_cs";
export var authorText = "p_mV d_cg d_cs";
export var textCenter = "p_dv d_dv";
export var searchWrapper = "p_qV d_by d_bN d_dq d_ck d_Y";
export var searchInput = "p_qW d_cD d_v d_b3";
export var searchBtn = "p_qX d_b0 d_bB d_bN d_Z d_cC d_dz";
export var text = "p_m4 d_cq";
export var elementWrapper = "p_nY d_ck";
export var select = "p_qY d_v d_b3";
export var articleImageWrapper = "p_mL d_cr d_Y d_T";
export var articleImageWrapperIcon = "p_mM d_cr d_Y d_T";
export var articleImageWrapperColumns = "p_qZ d_cr d_fg d_Y";
export var contentPadding = "p_q0 d_cR";
export var otherPadding = "p_q1 d_cL";
export var paginationWrapper = "p_q2";
export var pagination = "p_q3";
export var disabledPagination = "p_q4";
export var breakMe = "p_q5";
export var activePage = "p_q6";
export var next = "p_q7";
export var pages = "p_q8";
export var searchTitle = "p_q9 d_cq";
export var categoryTitle = "p_rb d_cq d_Y";
export var searchText = "p_rc d_cs";
export var bold = "p_rd";
export var field = "p_rf";
export var dateTag = "p_mC d_k d_cq d_dp";
export var icon1 = "p_mD d_cN";
export var icon2 = "p_mF d_cN d_cH";
export var tagBtn = "p_mG d_b0 d_cC d_dn d_dz";
export var linksWrapper = "p_mY d_cg d_dv";
export var header = "p_mt d_v d_Y";
export var headerImage = "p_rg d_bc d_Z d_7 d_6 d_3 d_8";
export var separator = "p_mT d_cX";
export var resultImage = "p_rh";